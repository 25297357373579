body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --offline-twitch-color: 210, 44, 50;
  --online-twitch-color: 59, 181, 31;
  --twitch-color: #000000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

/* *::-webkit-scrollbar {
  display: none;
} */

.react-calendar {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  width: 350px;
  background-color: white;
  border: 0;
  font-family: inherit;
  border-radius: 8px;
  box-shadow: 1px 4px 5px rgb(229 231 235);
  z-index: 1001;
}

.react-calendar__navigation {
  margin-bottom: 0;
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, minmax(0px, 1fr));
  padding: 10px;
  gap: 8px;
}

.react-calendar__navigation button {
  padding: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 12px;
}

.react-calendar__navigation button:nth-child(3) {
  grid-column-start: span 3;
}

.react-calendar__viewContainer {
  padding: 8px;
}

.react-calendar__month-view__weekdays {
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0px, 1fr));
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, minmax(0px, 1fr));
  gap: 6px;
}

.react-calendar__month-view__days button {
  margin: 0;
  padding: 0px;
  border-radius: 12px;
  width: 30px;
  height: 30px;
  align-self: center;
  justify-self: center;
}

.react-calendar__month-view__weekdays__weekday {
  border-radius: 12px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  color: rgb(183, 183, 183);
  font-weight: 500;
  font-size: 14px;
}

.react-calendar__decade-view__years,
.react-calendar__year-view__months {
  display: grid !important;
  grid-template-columns: repeat(3, minmax(0px, 1fr)) !important;
}

.react-calendar__tile.react-calendar__decade-view__years__year,
.react-calendar__tile.react-calendar__year-view__months__month {
  align-self: center;
  justify-self: center;
  padding: 12px;
  border-radius: 8px;
}

/* prev month day */
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__tile--active {
  background-color: black;
  color: white;
  transition: 0.3s all;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: black;
  color: white;
  transition: 0.3s all;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #e6e6e6;
  transition: 0.3s all;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: rgb(54, 54, 54);
  color: white;
}

.react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
  color: white;
}

.offline-twitch {
  --twitch-color: var(--offline-twitch-color);
  -webkit-animation: twitch 2s ease-in-out infinite;
  -moz-animation: twitch 2s ease-in-out infinite;
  animation: twitch 2s ease-in-out infinite;
}

.online-twitch {
  --twitch-color: var(--online-twitch-color);
  -webkit-animation: twitch 2s ease-in-out infinite;
  -moz-animation: twitch 2s ease-in-out infinite;
  animation: twitch 2s ease-in-out infinite;
}

@-webkit-keyframes twitch {
  0% {
    background-color: rgb(var(--twitch-color), 0);
  }
  50% {
    background-color: rgb(var(--twitch-color), 0.2);
  }
  100% {
    background-color: rgb(var(--twitch-color), 0);
  }
}
@-moz-keyframes twitch {
  0% {
    background-color: rgb(var(--twitch-color), 0);
  }
  50% {
    background-color: rgb(var(--twitch-color), 0.2);
  }
  100% {
    background-color: rgb(var(--twitch-color), 0);
  }
}
@keyframes twitch {
  0% {
    background-color: rgb(var(--twitch-color), 0);
  }
  50% {
    background-color: rgb(var(--twitch-color), 0.2);
  }
  100% {
    background-color: rgb(var(--twitch-color), 0);
  }
}

.move1 {
  -webkit-animation: move1 10s infinite reverse;
  -moz-animation: move1 10s infinite reverse;
  animation: move1 10s infinite reverse;
}

@-webkit-keyframes move1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes move1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes move1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0px);
  }
}

.move2 {
  -webkit-animation: move2 8s infinite reverse;
  -moz-animation: move2 8s infinite reverse;
  animation: move2 8s infinite reverse;
}

@-webkit-keyframes move2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes move2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes move2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0px);
  }
}

.move3 {
  -webkit-animation: move3 12s infinite reverse;
  -moz-animation: move3 12s infinite reverse;
  animation: move3 12s infinite reverse;
}

@-webkit-keyframes move3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes move3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes move3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(0px);
  }
}

.move4 {
  -webkit-animation: move4 18s infinite reverse;
  -moz-animation: move4 18s infinite reverse;
  animation: move4 18s infinite reverse;
}

@-webkit-keyframes move4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes move4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes move4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.move5 {
  -webkit-animation: move5 10s infinite reverse;
  -moz-animation: move5 10s infinite reverse;
  animation: move5 10s infinite reverse;
}

@-webkit-keyframes move5 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(120px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes move5 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(120px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes move5 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(120px);
  }
  100% {
    transform: translateY(0px);
  }
}

.move6 {
  -webkit-animation: move6 10s infinite reverse;
  -moz-animation: move6 10s infinite reverse;
  animation: move6 10s infinite reverse;
}

@-webkit-keyframes move6 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-moz-keyframes move6 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes move6 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0px);
  }
}

.move7 {
  -webkit-animation: move7 8s infinite reverse;
  -moz-animation: move7 8s infinite reverse;
  animation: move7 8s infinite reverse;
}

@-webkit-keyframes move7 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-moz-keyframes move7 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes move7 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}

.move8 {
  -webkit-animation: move8 12s infinite reverse;
  -moz-animation: move8 12s infinite reverse;
  animation: move8 12s infinite reverse;
}

@-webkit-keyframes move8 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-120px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-moz-keyframes move8 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-120px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes move8 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-120px);
  }
  100% {
    transform: translateX(0px);
  }
}

.move9 {
  -webkit-animation: move9 18s infinite reverse;
  -moz-animation: move9 18s infinite reverse;
  animation: move9 18s infinite reverse;
}

@-webkit-keyframes move9 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-moz-keyframes move9 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes move9 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}

.move0 {
  -webkit-animation: move0 10s infinite reverse;
  -moz-animation: move0 10s infinite reverse;
  animation: move0 10s infinite reverse;
}

@-webkit-keyframes move0 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-moz-keyframes move0 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes move0 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* Manage User and Manage device DashBoard */

/* main page */
.dashboard_page_view_ondark,
.dashboard_page_view{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 1px;
}
/* to change background color for darkmode */
.dashboard_page_view_ondark{
  background-color: rgb(19, 39, 53);
  color: white;
}
/* logo and data cards view part */
.aside_view,
.aside_view_ondark{
  min-width: 200px;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 4px;
  background: white;
  box-shadow: 1.5px 1.5px 1.5px rgb(126, 126, 126);
  resize: horizontal;
  max-width: 400px;
  overflow: hidden;
}

.aside_view_ondark{
  background-color: rgb(19, 39, 53);
  box-shadow: 1.5px 1.5px 1.5px rgb(4, 11, 16);
}
.aside_view div{
  width: 100%;
}
/* home button under logo */
.home_button_search{
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 4px;
  border-radius: 4px;
  align-items: center;
  gap: 2px;
}
 .home_button{
  background: rgb(2, 153, 110);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: .8rem;
  padding: 4px;
  width: 100%;
  height: 30px;
  border-radius: 8px;
}
.home_button:hover{
  background: rgb(84, 205, 170);
  color: #000000;
  cursor: pointer;
}
#scrollbar-hide::-webkit-scrollbar{
display: none;
}
/* search input label */
.search_input_box_ondark,
.search_input_box{
  width:auto;
  padding: 4px;
}
.search_input_box_ondark input,
.search_input_box input{
  width: 100%;
  height: 30px;
  border-radius: 6px;
  border: .1px solid rgb(21, 20, 20);
  background: gainsboro;
}
.search_input_box_ondark input{
  color: #000000;
  background: white;
} 
/* data cards view aside */
.cards_container{
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.card_view,
.card_view_active{
  width: 100%;
  height: fit-content;
   border: 1px solid rgb(17, 17, 17);
   display: grid;
   grid-template-columns:40% 1% auto;
   gap: 4px;
   padding: 4px;
   border-radius: 8px;
   font-size: .9rem;
   background: linear-gradient(to bottom,#e4e47d,#ffffff);
   color: black;
   text-align: left;
   cursor: pointer;
}
.card_view_active{
  background: linear-gradient(170deg,#ff2503,#ffffff);
}
.card_view:hover{
  border: 2px solid rgb(188, 188, 188);
}
.card_view div,
.card_view_active div{
  overflow: hidden;
white-space: nowrap;
  text-overflow: ellipsis;
}
.card_view div:nth-child(3),
.card_view_active div:nth-child(3){
  text-align: right;
}
.card_view div div:nth-child(2),
.card_view_active div div:nth-child(2){
  font-size: .7rem;
}

/* main body */
.main_body{
  height: 100%;
  display: grid;
  grid-template-rows: 15% 85%;
  resize: horizontal;
  overflow: hidden;
  width:100%;
}
.header_darkMode,
.header{
  display: grid;
  grid-template-columns: 85% 5% 10%;
  align-items: center;
  padding: 0px 8px;
  background: white;
  box-shadow: 1.5px 1.5px 1.5px rgb(126, 126, 126); 
}
.header_darkMode{
  background-color: rgb(19, 39, 53);
  box-shadow: 1.5px 1.5px 1.5px rgb(4, 11, 16);
}
.user_name{
  font-weight: bold;
}
.mode_icon
.signout{
  width: fit-content;
  padding: 4px;
  background:none;
}
.mode_icon:hover,
.signout:hover{
  width: fit-content;
  padding: 4px;
  background: rgba(163, 163, 163, 0.168);
  border-radius: 4px;
}
/* data view table  user or device*/
.data_view_table{
  width: 100%;
  height: 100%;
  padding: 8px;
  display: grid;
  grid-template-rows: 10% 90%;
}
.action_buttons{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  padding: 8px 20px;
}
.action_buttons button{
  padding: 4px;
  width: 100px;
  border: 1px solid gainsboro;
  border-radius: 4px;
}

.save_btn{
  background-color: rgb(12, 222, 113);
}
.update_btn{
  background-color: orange;
}
.delete_btn{
  background-color: red;
}
.action_buttons button:hover{
  background-color: rgba(150, 150, 150, 0.089);
}

/* data form view */

.data_card_box{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  padding: 4px;
  font-size: .8rem;
}
.data_view_cards_form{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 5px;
}

.data_card_dark,
.data_card{
  display: grid;
  grid-template-columns: 40% auto;
  gap: 4px;
  border: .1px solid black;
  min-height: 40px;
  max-height: fit-content;
  border-radius: 8px;
  align-items: center;
  padding: 4px;
  background: gainsboro;
  box-shadow: 2px 2px 2px rgb(168, 167, 167);
}
.data_card_dark{
  color: #ffffff;
   background-color: rgb(19, 39, 53);
  box-shadow: 2px 2px 2px rgb(4, 11, 16);
  border: .1px solid rgba(154, 154, 155, 0.723);
}
.data_card_dark div,
.data_card div{
  border: .1px solid black;
  height: 100%;
  min-height: 40px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  padding: 3px;
  align-items: center;
  overflow: hidden;
white-space: nowrap;
  text-overflow: ellipsis;
}
.data_card_dark div{
  border: .1px solid rgba(220, 220, 220, 0.651);
}

.data_card .input_value,
.data_card .input_edit,
.data_card_dark .input_value{
  width: 100%;
  background: gainsboro;
  border: none;
  overflow: hidden;
white-space: nowrap;
  text-overflow: ellipsis;
  font-size: .8rem;
  max-height: 30px;
}
.data_card_dark .input_value{
  background-color: rgb(19, 39, 53);
}
.data_card_dark .input_edit,
.data_card .input_edit{
  background-color: #ffffff;
  color: #000000;
}
.input_value{
  background: none;
  height: 100%;
}
.input_edit{
  height: 30px;
  border-radius: 4px;
  font-size: .8rem;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid gainsboro;
}

.device_active nav,
.device_inactive nav{
  width: 5px;
  padding: 6px;
  border-radius: 50%;
  background-color: #ff2503;
}
.device_active nav{
  background-color: #09cf62;
}
.account_ids{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  height: fit-content;
  justify-items: center;
  gap: 2px;
}

.account_ids nav,
.data_card .account_ids nav{
  display: flex;
  align-items: center;
  border: .1px solid gainsboro;
  border-radius: 4px;
  padding: 4px;
  height: fit-content;
}
.data_card .account_ids nav{
  border: .1px solid black;
}
.account_ids nav input{
  height: 30px;
  text-align: center;
}
.add_more{
  display: grid;
  grid-template-columns: 92% 5%;
}
.add_more span:last-child{
  width: 2px;
  height: 2px;
  padding: 9px 9px;
  border: .1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data_card_dark .add_more span:last-child{
  border: .1px solid gainsboro;
}
.add_more span:last-child:hover{
  cursor: pointer;
  background: #06dd95;
}
.remove_button:hover{
  color: #ff2503;
  cursor: pointer;
  font-size: 1rem;
}
.meta{
  height:fit-content;
}
.meta div{
  min-height: 50px;
}
.data_card_dark .meta div .meta_input{
  border: .1px solid rgba(220, 220, 220, 0.699);
}
.meta div .meta_input{
  border: .1px solid black;
  border-radius: 4px;
}


/* user details */
 .data_card_dark_user,
.data_card_user{
  width: 200%;
  padding: 4px;
  gap: 6px;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  background: gainsboro;
 border-radius: 8px;
 border: .1px solid black;
 justify-content: center;
 font-size: .8rem;
}
.data_card_dark_user{
  background-color: rgb(19, 39, 53);
  box-shadow: 1.5px 1.5px 1.5px rgb(4, 11, 16);
  border:.1px solid #ffffffa7;
}
.data_card_dark_user div div{
  border: .1px solid rgba(220, 220, 220, 0.791);
}
.user_info_labels{
  display: grid;
  grid-template-columns: 30% auto;
  gap: 8px;
  border-radius: 6px;
  padding: 4px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
    text-overflow: ellipsis;
}
.user_info_labels div{
  border: .1px solid black;
  padding: 8px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 40px;
}
.user_address_view{
  width: 100%;
  min-height: 30px;
  border: .1px solid rgba(220, 220, 220, 0.747);
  border-radius: 6px;
}
.address_view,
.address_view_ondark{
  display: flex;
  flex-direction: column;
  min-height: 40px;
  width: 80%;
  padding: 4px;
  border-radius: 8px;
  justify-content: center;
}

.address_table_ondark,
.address_table{
  display: grid;
  grid-template-columns: 30% auto;
  margin-top: 2px;
  align-items: center;
  border: none;
}
.address{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow: hidden;
  white-space: nowrap;
    text-overflow: ellipsis;
}
.address_view{
  border: .1px solid black;
}
.address_name{
  display: flex;
  align-items: flex-start;
}
.address_table{
  display: grid;
  grid-template-columns: 30% auto;
  align-items: center;
  border: .1px solid black;
  border-radius: 8px;
  min-height: 40px;
  margin: 4px;
  padding: 4px;
}
.address_table_data{
  display: flex;
  flex-direction:column;
  border: .1px solid black;
  border-radius: 8px;
}
.address_table_data_view{
  display: grid;
  grid-template-columns: 30% auto;
  min-height: 40px;
  padding: 4px;
  gap: 5px;
}
.address_table_data_view div{ 
  border: .1px solid black;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px;
  height: 40px;
  font-size: .8rem;
  overflow: hidden;
  white-space: nowrap;
    text-overflow: ellipsis;
} 
.address_table_data_view .input_value,
.user_info_labels .input_value{
  border: none;
  font-size: .8rem;
}

/* responsive screen using media */
@media screen and (max-width:900px) {
  .data_view_cards_form{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .account_ids{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .data_card_dark_user,
  .data_card_user{
    width: 100%;
  }
}